@charset "UTF-8";
/* CSS Document */

$px : 0.242vw;
$ppx : 0.078vw;

@include sp{
	
	.sp {
	  display: block !important;
	}
	
	.pc {
	  display: none !important;
	}
		
}//@sp


* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}


body.showBlk * {background-color: rgba(255,0,0,.2)}
body.showBlk * * {background-color:rgba(0,255,0,.2)} 
body.showBlk * * * {background-color:rgba(0,0,255,.2)} 
body.showBlk * * * * {background-color:rgba(255,0,255,.2)} 
body.showBlk * * * * * {background-color:rgba(0,255,255,.2)} 
body.showBlk * * * * * * {background-color:rgba(255,255,0,.2)} 
body.showBlk * * * * * * * {background-color:rgba(255,0,0,.2)} 
body.showBlk * * * * * * * * {background-color:rgba(0,255,0,.2)} 
body.showBlk * * * * * * * * * {background-color:rgba(0,0,255,.2)}


a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
}

ul  {
	list-style: none;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure {
	margin: 0;
	padding: 0;
}

header,nav,main,footer,section,aside{
	display: block;
}


form {
	margin-bottom: 0;
}

img {
	vertical-align:bottom;
}

::selection {
	color: #fff;
	background: #2C3F89;
}

::-moz-selection {
	color: #2C3F89;
	//background: #00aebb;
}

input::selection,
textarea::selection {
	color: #221814;
	background: #dbdcdc;
}

input[type=text],
input[type=tel],
input[type=email],
textarea,
button{
	-webkit-appearance: none;
	appearance: none;
	border: none;
	 
}

p{
	line-height: 1.82;
	font-size: 1.6rem;
	text-align: justify;
	@include Ten;
	font-style: normal !important;
	@include sp{
		font-size: 1.4rem;	
	}//@sp
}

.ar{
	text-align: right;
}
.ac{
	text-align: center;
}

i{
	font-style: normal;
}

.fr{
	float: right;
}


.sp{
	display: none;
}

.idx{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
}


.nh{
	opacity: 0;
	pointer-events: none;
}

/* !!------------------------------------ */
/* !! 共通css */

html{
	font-size: 62.5%;
	margin: 0;
}

body{
	margin: 0;
	position: relative;
	margin: 0 auto;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	background: #fff;
	color: $txt;
	font-size: 1.5rem;
	@include gothic;
	@include sp{
		min-width: 320px;	
	}//@sp
}

h2{
	@include MINI;
	color: $base;
	position: 1px;
	transition: 1000ms;
	opacity: 1;
	&.inv{
		@include transform(translateY(40%));
		opacity: 0;
	}
	i.en{
		font-size: 45px;
		line-height:1.04;
		letter-spacing: .04em;
		display: block;
		position: relative;
		@include pc{
			height: 38px;	
		}//@pc
		@include gt1280{
			height: auto !important;	
			font-size: 45*$ppx !important;
			@include pfix(.4em);
		}//@gt1281
		@include sp{
			font-size: 33*$px;	
		}//@sp
	}
	i.ja{
		display: block;
		margin-top: 30px;
		margin-bottom: 60px;
		font-size: 15px;
		@include Ten;
		letter-spacing:0.16em;
		line-height: 1;
		@include sp{
			font-size: 14*$px;
			margin-top: 25*$px;
		}//@sp
		@include gt1280{
			font-size: 15*$ppx;
		}//@gt1280
	}
}



//!! gnav
#nav{
	height: 34px;
	padding: 50px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	height: 138px;
	z-index: 10;
	.inner{
		max-width: 1280px;
		width: 100%;
		margin: 0 auto;
		@include gt1280{
			max-width: 100%;	
			padding: 0;
		}//@gt1280
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
	}
	margin: 0 auto;
	@include sp{
		height: 102px;
		min-width: 320px;
		padding: 0 22px;
		.logo{
			width: 147px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
	}//@sp
	align-items: center;
	position: fixed;
	top: 0;left: 0;
	width: 100%;
	min-width: 1280px;
	z-index: 10;
	transition: 200ms ease-in-out;
	#btn-menu{
		height: 34px;
		width: 34px;
		margin-right: 0;
		@include flex-centering;
		cursor: pointer;
		@include tap(1.1);
		@include bg-img(url(/img/btn-menu.svg),22px); //$url,$size,$position,$repeat
		body.menu_on & {
			@include bg-img(url(../../img/btn-menu-close.svg),16px); //$url,$size,$position,$repeat
			@include sp{
				@include bg-img(url(/img/btn-menu-close_sp.svg)); 
			}//@sp
		}
		@include sp{
			@include bg-img(url(/img/btn-menu_sp.svg)); //$url,$size,$position,$repeat
		}//@sp
	}
	body.moved & {
		
		@include pc{
			padding:0 50px ;
			height: 60px;
			background: rgba(255, 255, 255, 0.3);	
		}//@pc
		
		@include sp{
			height: 54px;
			background: rgba(255, 255, 255, 0.8);	
		}//@sp
	}
	@include pc{
		body.menu_on & {
			background: rgba(255, 255, 255, 0.9);
		}	
	}//@pc
	@include sp{
		body.menu_on & {
			background: #FFF;
		}	
	}//@sp
	
	//!! index
	.index-wrap{
		position: absolute;
		bottom: 0;
		right: 0;
		ul{
			position: absolute;
			top: 0;right: 0;
			background: $base;
			background: rgba(255, 255, 255, 0.9);
			height: 100vh;
			width: 400px;
			@include sp{
				width: 100vw;
				padding: 50px 30px;
			}//@sp
			padding: 50px 30px;
			text-align: left;
			transition: 600ms cubic-bezier(0, 0.49, 0, 1);
			@include transform(translateX(100%));
			body.menu_on &{
				@include transform(translateX(0));
				@include sp{
					background: #fff;	
				}//@sp
			}
			li{
				margin-bottom: 40px;
				@include sp{
					margin-bottom: 30*$px;	
				}//@sp
			}
			a{
				@include MINI;
				font-size: 30px;
				line-height: 1;
				text-align: left;
				letter-spacing: .08em;
				&:hover{
					color: $base;
				}
			}
		}
	}
}

#main{
	position: static;
	display: block;
	min-width: 1280px;
	z-index: 1;
	@include sp{
		min-width: 320px;
		min-height: 500px;	
	}//@sp
	@include pc{
		min-height: calc(100vh - 134px - 243px - 70px );	
	}//@pc
}

/* !!------------------------------------ */
/* !! BG */

//!! ノイズ
#noise{
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: .5;
	display: none;
	mix-blend-mode: multiply ;
	transform: translateZ(1px);
	will-change:mix-blend-mode;
	@media (-webkit-min-device-pixel-ratio: 2){
	    html.chrome & {
		    display: block;
		    opacity: .5;
		    transition: 300ms;
		    transition-delay: 200ms;
	    }
	    html.chrome body.scrolling & {
		    display: none;
		    opacity: 0;
	    }
	}	
	html.safari & {
		@include pc{
			//opacity: 0;
			//stransition: 300ms;
			&.on{
				opacity: 1;
			}
		}//@pc
	}
	
	.img{
		width: 178vh;
		height: 100vh;
		overflow: hidden;
		position: absolute;
		top: 50%;
		left: 50%;
		//opacity: .4;
		opacity: 1;
		transition: 2000ms opacity;
		@include transform(translate(-50%,-50%));
		
		img.noise{
			width: 100% !important;
			height: auto !important;
			display: block;
			background: #ccc;
			animation: flip 2000ms steps(46) infinite;
		}
		body.view_contact & {
			opacity: 0;
			animation: none;
		}
	}
	pointer-events: none;
	
	@media (min-aspect-ratio: 1280/720) {
		.img{
			width: 100vw;
			height: 56vw;
		}
	}
	
}


@keyframes flip {
  from {
    @include transform(translate3d(0,0,0));
  }
  to {
    @include transform(translate3d(0,-100%,0));
  }
}


#bg-img-grp{
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;left: 0;
	width: 100%;
	min-width: 1280px;
	z-index: 0;
	
	@include sp{
		min-width: 0;	
	}//@sp
	.bg{
		&.imgl{
			opacity: 0;
		}
		opacity: 1;
		transition: 1000ms opacity;
		display: block;
		width: 100% !important;
		height: auto !important;
		position: absolute;
		top: 0;left: 0;
		will-change:transform;
		backface-visibility: hidden;
		img{
			width: 100% !important;
			height: auto !important;
			display: block;
			margin: 0 auto;
			backface-visibility: hidden
		}
	}
	
	.tree{
		height: auto;
		&.imgl{
			opacity: 0;
			animation: none;
			
			//@include transform(scale(1.2));
		}
		opacity: 1;
		transition: 1500ms opacity;
		z-index: 0;
		@include transform(scale(1));
		img{
			width: 1000% !important;
			backface-visibility: hidden;
			height: auto;
			animation: flip2 2000ms steps(10) infinite;
		}
	}
	
}//#bg-img-grp



@keyframes flip2 {
  from {
    @include transform(translate3d(0,0,0));
  }
  to {
    @include transform(translate3d(-100%,0,0));
  }
}


/* !!------------------------------------ */
/* !! TOP */


//!! header
#header{
	text-align: center;
	padding-top: 138px;
	position: relative;
	min-width: 1280px;
	z-index: 10;
	//transform: translateZ(2px);
	@include pc{
		margin-bottom: 948px;		
	}//@pc
	@include gt1280{
		margin-bottom: 948*$ppx;		
	}//@pc
	
	@include sp{
		min-width: 0;
		padding-top: 102px;
		margin-bottom: 283*$px;
	}//@sp
}


//!! h1

h1{
	display: inline-block;
	text-align: right;
	//background: #fff;
	position: relative;
	z-index: 2;
	position: absolute;
	top: 138px;left: 0;
	width: 100%;
	padding: 0 50px;
	transform: translateZ(1px);
	mix-blend-mode: multiply !important;
	&.animationEnd{
		mix-blend-mode: normal !important;
	}
	.txt{
		transition: 1000ms;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
		letter-spacing: 0;
		>div{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			letter-spacing: 0;
			margin: 0;
			>i{
				letter-spacing: 0;
				margin-right: 22px;
				&:last-of-type{
					margin-right: 0;
				}
				&.S{
					text-indent: -.04em !important;
				}
			}
		}
		&.inv{
			@include transform(translateY(20%));
			opacity: 0 !important;
			&:after{
				display: none !important;
			}
			&:before{
				display: none !important;
			}
		}
		html.wf-kepler-std-caption-n7-active & {
			@include transform(translateY(0));
			opacity: 1;
		}
		@include MINI;
		//font-size: 115px;
		//text-indent: -.03em;
		//letter-spacing: 0.22em;
		font-size: 113px;
		letter-spacing: 0.176em;
		text-align: center;
		line-height: 1;
		@include pfix(.2em);
		&:after{
			content: "";
			display: inline-block;
			width: 100%;					
		}
		@include gt1280{
			font-size:  113*$ppx;
			//text-indent: -.1.5em;
			>div > i{
				margin-right: 22*$ppx;
			}
		}//@gt1280
		@include sp{
			font-size: 40*$px;
			>div > i{
				margin-right: 10*$ppx;
			}
		}//@sp
	}
	span{
		@include Ten;
		font-size: 18px;
		color: #000;
		letter-spacing: .1em;
		line-height: 1;
		margin-bottom: 25px;display: block;
	}
	.img.inv{
		@include transform(translateY(20%));
		opacity: 0;
		&:after{
			display: none !important;
		}
		&:before{
			display: none !important;
		}
	}
	.img{
		opacity: 1;
		transition: 800ms;
		transition-delay: 500ms;
		position: relative;
		&:after{
			content: "";
			width:100%;
			height:100%;
			position: absolute;
			top: 0;
			left: 12px;
			@include bg-img(url(../../img/ttl_af.svg)); //$url,$size,$position,$repeat				
			@include sp{
				@include bg-img(url(../../img/ttl_sp_af.svg));	
			}//@sp
		}
		&:before{
			content: "";
			width:100%;
			height:100%;
			position: absolute;
			top: 0;
			left: -12px;
			background: #fff;
			@include bg-img(url(../../img/ttl_bf.svg)); //$url,$size,$position,$repeat				
			@include sp{
				@include bg-img(url(../../img/ttl_sp_bf.svg));	
			}//@sp
		}
	}
	@include gt1280{
		display: block;
		.img{
			width: 100%;
			img{
				width: 100% !important;
				height: auto !important;
				
			}
		}
	}//@gt1280
	@include sp{
		width: 100%;
		padding:0 22px;
		top: 102px;
		img{
			width: 100% !important;
			height: auto !important;
		}
		span{
			margin-bottom: 10px;
			font-size: 15*$px;
		}
	}//@sp
}




//!!section

main > section {
	margin-bottom: 100px;
	transition: 800ms;
	position: relative;
	z-index: 3;
	@include transform(translateY(0));
	&.inv{
		opacity: 0;
		@include transform(translateY(100px));
	}
	@include gt1280{
		margin-bottom: 100*$ppx;	
	}//@gt1280
	@include sp{
		margin-bottom: 100*$px;	
	}//@sp
	>.inner{
		padding-left: 434px;
		position: relative;
		//max-width: 1030px;
		margin: 0 auto;
		.lead-img{
			position: absolute;
			
		}
	}
	@include sp{
		.inner{
			padding-left: 0;
			padding:0 22*$px;
		}	
	}//@sp
}

/* !! - - -  */
/* !! From_Wall_Street_to_Hollywood */

#From_Wall_Street_to_Hollywood{
	
	mix-blend-mode: multiply;

	width: 100%;
	@include sp{
		margin-bottom: 120*$px;	
	}//@sp
	.inner{
		@include pc{
			padding-left: 50%;	
		}//@pc
		@include sp{
			padding: 0;
			padding-left: 147*$px;
			padding-right: 27*$px;
			//@include textshadow($color:rgba(#fff,.4));
		}//@sp
	}
	.txt{
		width: 330px;
		@include pc{
			margin-left: 175px;	
		}//@pc
		@include gt1280{
			width: 330*$ppx;
			margin-left: 175*$ppx;		
		}//@gt1280
		@include sp{
			width: 100% !important;	
		}//@sp
	}
	
	h2{
		@include pc{
			.en{
				height: 90px;
				white-space: nowrap;
				&.glitch{
					&:after{
						white-space: pre;
						content: "From Wall Streetto\AHollywood" !important;
					}
					&:before{
						white-space: pre;
						content: "From Wall Street\Ato Hollywood" !important;
					}
				}
			}	
		}//@pc
		@include sp{
			.en{
				white-space: nowrap;
				&.glitch{
					&:after{
						white-space: pre;
						text-align: left;
						content: "From\AWall Street\Ato Hollywood" !important;
					}
					&:before{
						white-space: pre;
						text-align: left;
						content: "From\AWall Street\Ato Hollywood" !important;
					}
				}
			}	
				
		}//@sp
		@include gt1280{
			height: auto !important;	
		}//@gt1280
		i.ja{
			//margin: 68px 0;
			//margin-top: 53px;
			margin-bottom: 50px;
			@include sp{
				margin: 20px 0;	
			}//@sp
		}
	}
	
	p{
		font-size: 12px;
		line-height: 2.5;
		letter-spacing: .08em;
		@include sp{
			font-size: 12*$px;	
		}//@sp
		@include gt1280{
			font-size: 12*$ppx;	
		}//@gt1280
		img{
			display: inline-block;
			position: relative;
			margin-left: -2px;
			@include transform(translateY(2px));
			@include gt1280{
				margin-left: -2*$ppx;
				@include transform(translateY(#{2*$ppx}));
				width: 22*$ppx;
				height: auto;
			}//@gt1280
		}
	}
	
}//#From_Wall_Street_to_Hollywood



/* !! - - -  */
/* !! Service */

#Performance{
	mix-blend-mode: multiply;
	margin-bottom: 110px;
	@include sp{
		padding: 0 55*$px;
		margin-bottom: 133*$px;
		.inner{
			padding: 0;
			text-align: center;
		}
	}//@sp
	@include pc{
		padding-left: 50%;
		.inner{
			width: 595px;
			padding: 0;
			margin: 0;
			margin-left: -44px;
		}
	}//@pc
	@include gt1280{
		margin-bottom: 110*$ppx;
		.inner{
			width: 595*$ppx;
			padding: 0;
			margin: 0;
			margin-left: -44*$ppx;
		}	
	}//@gt1280
	h2{
		@include sp{
			.en{
				//background:#eee;
				height: 30*$px;
			}	
		}//@sp
		.ja{
			margin-bottom: 30px;
			@include gt1280{
				margin-bottom: 30*$ppx;	
			}//@gt1280
		}
	}
	
	.idx{
		justify-content: flex-start;
		@include sp{
			margin-top: 30*$px;	
		}//@sp
		dl{
			width: 190px;
			@include Ten;
			@include gt1280{
				width: 190*$ppx;	
			}//@gt1280
			@include sp{
				width: 100%;
				margin-bottom: 30*$px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}//@sp
			@include pc{
				padding: 35px 20px;
				@include gt1280{
						padding: 35*$ppx 20*$ppx;
				}//@gt1280
				border-right: $line;
				border-bottom: $line;
				&:nth-of-type(3n+1){
					padding-left: 0;
				}
				&:nth-of-type(3n+2){
					width: 215px;
					@include gt1280{
						width: 215*$ppx;	
					}//@gt1280
				}
				&:nth-of-type(3n+3){
					border-right: 0;
					padding-right: 0;
				}
				&:nth-last-of-type(-n + 3){
					border-bottom: 0;
				}
			}//@pc
			dt{
				color: $base;
				margin-bottom: 10px;
				letter-spacing: .02em;
				@include gt1280{
					margin-bottom: 10*$ppx;	
				}//@gt1280
				@include sp{
					position: relative;
					overflow: hidden;
				}//@sp
				.en{
					@include MINI;
					font-size: 14px;
					white-space: nowrap;
					@include sp{
						font-size: 18*$px;
						display: inline-block;
						min-width: 84*$px;
						position: relative;
						height: 20*$px;
						letter-spacing: 0;
						&:after,&:before{
							content: "";
							background: #7F7F7F;
							height: 1px;
							width: 50vw;
							display: block;
							position: absolute;
							top: 50%;
						}
						&:after{
							left: -18*$px;
							@include transform(translateX(-100%));
						}
						&:before{
							right: -18*$px;
							@include transform(translateX(100%));
						}

					}//@sp
					
					letter-spacing: .05em;
					line-height: 1;
					@include pc{
						display: block;
						height: 14px;
						//background: #eee;	
					}//@pc
					@include gt1280{
						font-size: 14*$ppx;
						white-space: nowrap;
						height: auto;
						//background: #eee;
						margin-bottom: -.25em;
					}//@gt1280
				}
				.ja{
					font-size: 10px;
					line-height: 1;
					@include Ten;
					letter-spacing: .04em;
					display: block;
					margin-top: 5px;
					@include pc{
						margin: 20px 0;
					}
					@include gt1280{
						font-size: 10*$ppx;
						margin: 20*$ppx 0;
					}//@gt1280
					@include sp{
						font-size: 10*$px;
						margin-top: 10px;
					}//@sp
				}
			}
			dd{
				font-size: 10px;
				text-align: justify;
				line-height: 1.82;
				letter-spacing: .04em;
				@include pfix(.82em);
				@include gt1280{
					font-size: 10*$ppx;	
				}//@gt1280
				@include sp{
					margin-top: 0;
					font-size: 12*$px;
					margin-top: 20*$px;
					text-align: center;
					letter-spacing: .1em;
				}//@sp
			}
		}
	}
	
}//#Paformance


/* !! - - -  */
/* !! Company */
#Company{
	mix-blend-mode: multiply;
	@include pc{
		padding-left: 50%;
		margin-bottom: 260px;
		@include gt1280{
			margin-bottom: 260*$ppx;	
		}//@gt1280
		.inner{
			width: 595px;
			padding: 0;
			margin: 0;
			margin-left: -44px;
			@include gt1280{
				width: 595*$ppx;
				margin-left: -44*$ppx;	
			}//@gt1280
		}
	}//@pc
	@include sp{
		h2{
			text-align: center;
			.en{
				//background:#eee;
				height: 30*$px;
			}	
		}
		padding: 0 30*$px;
		.inner{
			padding: 0;
		}
	}//@sp	
	.outline{
		@include sp{
			margin-top: 30*$px;	
		}//@sp
		dl{
			font-size: 12px;
			line-height: 1.82;
			@include Ten;
			@include pfix(.82em);
			@include gt1280{
				font-size: 12*$ppx;	
			}//@gt1280
			padding-bottom: 12px;
			@include sp{
				padding-bottom: 20*$px;	
			}//@sp
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			dt{
				letter-spacing: .2em;
				line-height: 2.3;
				@include sp{
					letter-spacing: -.04em;	
					font-size: 12*$px;
				}//@sp
			}
			dd{
				width: 460px;
				text-align: justify;
				line-height: 2.33;
				letter-spacing: .08em;
				ul{
					margin-top: 5px;
					li{
						padding-left: 1.2em;
						position: relative;
						line-height: 1.6;
						margin-bottom: 10px;
						&:before{
							content:"･";
							font-size: 24px;
							line-height: 24px;
							@include gt1280{
								font-size: 24*$ppx;
								line-height: 21*$ppx;	
							}//@gt1280
							@include sp{
								line-height: .9;
							}//@sp
							position: absolute;
							left: 0;top: 0;
						}
					}
				}
				@include gt1280{
					width: 460*$ppx;	
				}//@gt1280
				@include sp{
					width: calc(100% - #{95*$px});
					font-size: 12*$px;
					letter-spacing: -.04em;	
				}//@sp
				a{
					border-bottom: 1px dashed #000;
					padding-bottom: 3px;
				}
			}
		}
	}
	
}//#Company



/* !! - - -  */
/* !! Partner */
#Partner{
	.inner{
		padding: 0;
		h2{
			@include pc{
				text-align: center;	
			}//@pc
			@include sp{
				text-align: center;
				.en{
					//background:#eee;
					height: 30*$px;
				}	
			}//@sp
		}
	}
	ul.partner-company-list{
		margin-top: 55px;
		max-width: 780px;
		margin: 0 auto;
		@include sp{
			margin-top: 30*$px;	
		}//@sp
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: center;
		align-items: center;
		li{
			display: inline-block;
			margin-right: 0px;
			margin: 0 20px;
			margin-bottom: 45px;
			@include sp{
				margin-bottom: 21*$px;	
			}//@sp
			&.nm{
				margin: 0;
			}
			@include pc{
				&.nm-l{
					margin-left: 0;
				}
				&.nm-r{
					margin-right: 0;
				}
				&:nth-last-of-type(-n+5){
					margin-bottom: 0;
				}
			}
			@include sp{
				margin-right: 20*$px;
				@include flex-centering;
				&:nth-last-of-type(-n+2){
					margin-bottom: 0;
				}
				img{
					width: 100% !important;
					height: auto !important;
				}
				&._1{
					order:1;width: 122*$px;
				}
				&._2{
					width: 82*$px;
					order: 3;
				}	
				&._3{
					width: 110*$px;
					order: 5;
					//margin-bottom: 0;
				}
				&._4{
					order: 2;
					width: 64*$px;
				}
				&._5{
					order: 4;
					width: 54*$px;
				}
				&._6{
					order: 6;
					width: 59*$px;
				}
				&._7{
					order: 7;
					width: 49*$px;
				}
				&._8{
					order: 8;
					width: 108*$px;
				}
				&._9{
					order: 9;
					width: 108*$px;
				}
			}//@sp
			height: auto;
			align-items: center;
			display: -webkit-inline-flex;
			display: -moz-inline-flex;
			display: -ms-inline-flex;
			display: -o-inline-flex;
			display: inline-flex;
			img{
				display: block;
				vertical-align: middle;
			}
		}
		
	}//ul.partner-company-list
	
}


/* !! - - -  */
/* !! Contact */
/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

#Contact{
	.inner{
		max-width: 630px;
		padding: 0;
	}
	margin-top: 180px;
	@include sp{
		margin-top: 85*$px;
		.inner{
			padding: 0 30*$px;
		}
	}//@sp
	h2{
		text-align: center;
		@include sp{
			.en{
				//background:#eee;
				height: 30*$px;
			}	
		}//@sp
	}
	
	a.line_at{
		background: #00B901;
		display: block;
		border-radius: 6px;
		height: 164px;
		@include flex-centering;
		i{
			font-size: 12px;
			letter-spacing: .08em;
			margin-top: 20px;
			color: #fff;
			line-height: 1;display: block;
			font-weight: 500;
			@include gt1280{
				font-size: 14px;	
			}//@gt1280
			
		}
		@include sp{
			height: 110px;
			img{
				width: 88*$px;
				height: auto;
			}	
		}//@sp
	}
	
	.lead0{
		background: $gold;
		@include flex-centering;
		margin: 25px 0;
		font-size: 12px;
		color: #fff;
		height: 71px;
		border-radius: 6px;
		font-weight: 500;
		letter-spacing: .08em;
		@include gt1280{
				font-size: 14px;	
			}//@gt1280
		@include sp{
			margin: 12*$px 0;
			height: 110px;
		}//@sp
	}
	
	.lead{
		font-size: 10px;
		text-align: center;
		line-height: 2.4;
		letter-spacing: .2em;
		@include pfix(1.4em);
		color: #8f8f8f;
		font-weight: 600;
		@include gt1280{
			font-size: 14px;	
		}//@gt1280
		@include sp{
			padding: 30px 0;
			font-size: 12px;
			text-align: justify;	
			br{
				display: none;
			}
		}//@sp
	}
	
	#mailformpro{
		margin-top: 70px;
		padding-top: 0;
		.input-grp{
			>div:not(.mfp_err){
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				padding-left: 211px;
				position: relative;
				margin-bottom: 15px;
				@include sp{
					margin-bottom: 25*$px !important;
					&:not(.tel-wrap){
						display: block;
					}
					&.tel-wrap{
						-webkit-flex-wrap: wrap;
						-moz-flex-wrap: wrap;
						-ms-flex-wrap: wrap;
						-o-flex-wrap: wrap;
						flex-wrap: wrap;
						justify-content: space-between;
						div{
							width: 25% !important;
							input{
								text-align: center;
							}
						}
					}
					padding-left: 0;
					margin-bottom: 20*$px;	
				}//@sp
				>p{
					position: absolute;
					top: 0;left: 0;
					height: 50px;
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					align-items: center;
					color: $gold;
					font-size: 12px;
					@include gt1280{
						font-size: 14px;	
					}//@gt1280
					@include Ten;
					letter-spacing: .04em;
					@include sp{
						height: auto;
						width: 100% !important;
						position: relative;	
						margin-bottom: 10px;
					}//@sp
					span{
						height: 18px;
						line-height: 1;
						width: 45px;
						border-radius: 9px;
						@include flex-centering;
						background: $gold;
						color: #fff !important;
						letter-spacing: .02em;
						margin-left: 15px;
						font-style: normal !important;
						html.firefox & {
							@include minchou;
							font-style: normal;
							font-size: 11px;
						}
					}
				}
				>div{
					width: 100% !important;
				}
				textarea,input[type=text],input[type=email],input[type=tel]{
					&:focus{
						outline:none;
						background: #f0ffff;
					}
					display: block;
					padding: 10px;
					height: 50px;
					@include sp{
						height: 56*$px;	
					}//@sp
					width: 100% !important;
					min-width: 40px !important;
					max-width: 100% !important;
					margin: 0;
					background: $gray;
					border-radius: 4px;
					box-shadow: none;
					border: none;
					font-size: 14px;
				}
				textarea{
					background: $gray;
					height: 466px !important;
					@include sp{
						height: 200px !important;	
					}//@sp
				}
				i{
					width: 30px !important;
					@include flex-centering;
					padding:0 10px;
					height: 50px;
					&:after{
						content: "";
						width: 11px;
						height: 1px;
						background: #000;
						display: block;			
					}
				}
			}
		}//.input-grp
		
		.mfp_err{
			background: none !important;
			padding: 0;
			opacity: .6;
			&.tel{
				margin-top: 0 !important;
				
				@include pc{
					padding-left: 211px;	
				}//@pc
				&:last-of-type{
					margin-bottom: 10px !important;
				}
			}
		}
		
		.tel-wrap{
			margin-bottom: 0;
			& + .mfp_err{
				@include pc{
					margin-top: 10px !important;	
				}//@pc
				@include sp{
					margin-top: -20*$px !important;
				}//@sp
				
			}
		}
		
		.txtarea{
			margin-top: 15px;
			& + {
				.mfp_err{
					margin-top: -20*$px !important;
					margin-bottom: 15px;
				}
			}
		}
		
		.policy{
			font-size: 11px !important;
			padding: 15px;
			border: 1px solid #ddd;
			height: 200px;
			overflow: auto;
			@include gothic;
			h2{
				
				color: $txt;
				text-align: left;
			}
			h3{
				font-size: 12px;
				font-weight: bold !important;
			}
			p{
				font-size: 11px !important;
				margin-bottom: 10px;
				line-height: 2;
				@include gothic;
			}
		}
		
		.agreement{
			font-size: 13px;
			line-height: 1;
			margin: 0;
			margin: 10px 0;
			label{
				margin: 0;
			}
			span{
				width: 100%;
				line-height: 1;
				align-items: center;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
			}
			input{
				margin: 0;
				margin-right: 10px;
			}
			.mfp_err{
				position: relative;
				display: none;
			}
			
		}
		
		
	}//#mainform

	.btn-wrap{
		.reset{
			display: none !important;
		}
		input{
			width: 244px !important;
			height: 50px !important;
			background: $gold !important;
			text-shadow: none;
			color: #fff;
			font-size: 11px;
			letter-spacing: .16em;
			font-weight: bold;
			@include gt1280{
				font-size: 14px;	
			}//@gt1280
			@include sp{
				width: 100% !important;
				max-width: 100%;
				font-size: 12px;
				height: 70*$px !important;	
			}//@sp
		}
	}
	
	.mfp_buttons{
		font-size: 0;
		button{
			width: 244px !important;
			height: 50px !important;
			background: $gold !important;
			text-shadow: none;
			color: #fff;
			font-size: 11px;
			letter-spacing: .16em;
			font-weight: bold;
			margin: 0;
			margin:0 7px;
			&#mfp_button_cancel{
				background: #eee !important;
				color: $txt;
			}
			@include gt1280{
				font-size: 14px;	
			}//@gt1280
			@include sp{
				width: 100% !important;
				max-width: 100%;
				margin: 0;
				display: block;
				margin-bottom: 20*$px;
				font-size: 12px;
				height: 70*$px;	
				&:last-of-type{
					margin-bottom: 0;
				}
			}//@sp
		}
	}
	
	
	//!! mfp_phase_confirm_inner
	#mfp_phase_confirm_inner{
		h4{
			color: $gold;
			@include Ten;
			font-size: 15px;
			line-height: 1;
			margin-bottom: 15px;
			letter-spacing: .04em;
			@include pc{
				margin-top: 30px;
				font-size: 18px;	
			}//@pc
		}
		#mfp_confirm_table{
			border-bottom: 1px solid #ccc;
			margin-bottom: 20px;
			gt1280
			th,td{
				padding: 15px 10px;
				font-size: 12px;
				letter-spacing: .1em;
				@include gt1280{
					font-size: 14px;	
				}//@
			}
		}
		@include sp{
			padding: 0 30*$px;
			th{
				width: 130px !important;
				white-space: normal;
				font-size: 10px !important;
				text-align: justify;
			}
		}//@sp
	}

}//#Contact


/* !! - - -  */
/* !! thanks */
body.thanks{
	#header{
		margin-bottom: 0;
	}
	main{
		@include flex-centering;
		display: -webkit-flex !important;
		display: -moz-flex !important;
		display: -ms-flex !important;
		display: -o-flex !important;
		display: flex !important;
	}
}
.thanks-txt{
	border: 1px solid #ddd;
	//@include centering;
	padding: 20px;
	font-size: 12px;
	a{
		height: 50px;
		border-radius: 25px;background: #000;
		@include flex-centering;
		width: 200px;
		margin: 0 auto;
		margin-top: 15px;
		background: #eee;
	}
}



/* !! - - -  */
/* !! フッター */
#footer{
	position: relative;
	z-index: 1;
	height: 243px;
	background: #212121;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	padding: 0 125px;
	.logo{
		margin-left: -28px;
	}
	@include sp{
		display: block;
		height: auto;
		padding:50*$px 30*$px;
		.logo{
			margin: 0;
			width: 224*$px;
			display: block;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}	
	}//@sp
	#f-nav{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		width: 100%;
		justify-content: flex-end;
		@include sp{
			display: block;	
			margin: 60px 0;
		}//@sp
		ul{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			@include sp{
				display: block;	
			}//@sp
			li{
				margin-left: 40px;
				height: auto;
				@include sp{
					margin: 0;
					margin-bottom: 0;
					
				}//@sp
			}
			a{
				color: #666;
				@include GPP;
				height: auto;
				line-height: 1;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: center;
				letter-spacing: .16em;
				@include sp{
					font-size: 12px;
					padding: 15px 0;	
				}//@sp
				&:hover{
					color: #fff;
				}
			}
		}
	}
	
}//#footer

p.copy{
	background: #000;
	font-size: 12px;
	color: #fff;
	height: 70px;
	position: relative;
	z-index: 1;
	@include flex-centering;
	letter-spacing: .08em;
	@include pc{
		//display: none;	
	}//@pc
}




/* !!------------------------------------ */
/* !! アニメーション */

.skew {
  animation: skew 3s  infinite;
  transition: 0 !important;
}
@keyframes skew {
  0% {
    transform: none;
  }
  33% {
    transform: none;
  }
  33.3% {
    transform: skewX(10deg);
  }
  33.6% {
    transform: skewX(-10deg);
  }
  33.9% {
    transform: none;
  }
  66% {
    transform: none;
    text-shadow: none;
  }
  66.3% {
    transform: skewX(5deg);
  }
  66.6% {
    transform: skewX(-5deg);
  }
  66.9% {
    transform: none;
  }
  77% {
    transform: none;
    text-shadow: none;
  }
  77.3% {
    transform: skewX(10deg);
  }
  77.6% {
    transform: skewX(-10deg);
  }
  77.9% {
    transform: none;
  }
}

.flash {
  animation: flash .06s infinite;
  transition: 0s !important
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .8;
  }
}



@keyframes noise-anim{
  $steps:20;
  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))}{
      clip:rect(random(200)+px,9999px,random(150)+px,0);
    }
  }
}

.glitch:after{
	content:attr(data-text);
	height: 100%;
	position:absolute;
	top:0;left: 10px;
	text-shadow: -1px 0px 0px #ff0000;
	color:white;
	background:#fff;
	overflow:hidden;
	clip:rect(0,900px,0,0); 
	animation:noise-anim 1s linear alternate-reverse;
	animation-iteration-count: 1;
	@include sp{
		width: 100%;
		text-align: center;	
	}//@sp
}

@keyframes noise-anim-2{
  $steps:20;
  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))}{
      clip:rect(random(120)+px,9999px,random(100)+px,0);
      @include sp{
      		clip:rect(random(80)+px,9999px,random(100)+px,0);
      }//@sp
    }
  }
}
.glitch:before{
	content:attr(data-text);
	text-shadow: 1px 0px 0px #4500ff;
	height: 100%;
	position:absolute;
	left:-11px;
	top:0;
	color: #000;
	background:#fff;
	overflow:hidden;
	clip:rect(0,900px,0,0); 
	animation:noise-anim-2 1s infinite linear alternate-reverse;
	animation-iteration-count: 1;
	@include sp{
		width: 100%;
		text-align: center;	
	}//@sp
}

.hold-glitch{
	&:after{
		content: none !important
	}
	&:before{
		display: none !important;
	}
}